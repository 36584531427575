import axios from 'axios';
import { useAuthSlice } from '../store/auth-slice';
import { useFullPageLoadingSlice } from '../store/loading-slice';

const http = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}`,
  timeout: 30000,
  headers: {
    'content-type': 'application/json',
    Accept: 'application/json',
  },
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status, config, data } = error.response;
    const logout = useAuthSlice.getState().reset;
    const updateLoading = useFullPageLoadingSlice.getState().updateLoading;
    const urlArr = config?.url?.split('/');
    if (data.status === 'business_deactivated') {
      window.location.href = '/';
    }
    if (status === 401 && urlArr.at(-1) !== 'change_password') {
      // TODO: refresh token
      updateLoading(false);
      logout();
    }
    return Promise.reject(error);
  },
);

http.interceptors.request.use(
  (config) => {
    const token = useAuthSlice.getState().token;
    const validBaseUrl = config.baseURL!.startsWith(`${import.meta.env.VITE_BASE_URL}`);
    const isLinkedApps = config.url!.search(`linked_apps`);
    if (token && validBaseUrl && isLinkedApps === -1) {
      config.headers!.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

export default http;
