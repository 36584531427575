import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import workspaceService from '../../services/workspace-service';
import { Workspace } from '../../interfaces/workspace-interface';

export function useGetWorkspaces(
  business_id: string,
  configOptions: UseQueryOptions<any, any, Workspace[], any> = {},
) {
  return useQuery({
    enabled: !!business_id,
    queryKey: [workspaceService.workspacesQueryKey, business_id],
    queryFn: () => workspaceService.getWorkspaces(business_id),
    refetchOnWindowFocus: true,
    staleTime: 1000 * 30,
    ...configOptions,
  });
}
