import { Link, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../../utils/tailwind-class-names';
import { Workspace } from '../../../interfaces/workspace-interface';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';
import { getInitials } from '../../../utils/get-Initials';
import Tooltip from '@mui/material/Tooltip';
import { capitalizeFirstLetter } from '../../../utils/capitalize-first-letter';
import { useEmployeeFormSlice } from '../../../store/employee-form-slice';
import './workspaces-bar.scss';
import { useGetWorkspaces } from '../../../hooks/workspaces/use-get-workspaces';
import { useQueryClient } from '@tanstack/react-query';

interface WorkspacesBarProps {
  toggleDrawer?: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const WorkspacesBar = ({ toggleDrawer }: WorkspacesBarProps) => {
  const queryClient = useQueryClient();
  const { businessId } = useParams();
  const activeWorkspace = useActiveWorkspaceSlice((state) => state.workspace);
  const updateActiveWorkspace = useActiveWorkspaceSlice((state) => state.updateActiveWorkspace);
  const updateEmployeeForm = useEmployeeFormSlice((state) => state.updateEmployeeForm);

  const workspacesQuery = useGetWorkspaces(businessId!);

  const handlePreLoading = (workspace: Workspace) => {
    updateEmployeeForm(null);
    updateActiveWorkspace(workspace);
    queryClient.invalidateQueries();
  };

  return (
    <nav
      aria-label="Sidebar"
      id="sidebar-nav"
      className="group flex flex-col h-screen shrink-0 overflow-y-auto bg-brand-primary [&::-webkit-scrollbar]:hidden"
    >
      <div className="relative flex flex-col w-20 h-full p-3 space-y-3 transition-all duration-150 ease-[cubic-bezier(.57,.21,.69,1.25)] bg-brand-primary group-hover:w-40">
        {(workspacesQuery.data ?? []).map((workspace) => {
          const wsName = getInitials(workspace.title, true);
          return (
            // <Tooltip
            //   key={workspace.id}
            //   title={capitalizeFirstLetter(workspace.title)}
            //   placement="right"
            // >
            <button
              type="button"
              key={workspace.id}
              onClick={(e) => {
                handlePreLoading(workspace);
                toggleDrawer && toggleDrawer(e);
              }}
              className={classNames(
                workspace.id === activeWorkspace?.id
                  ? 'bg-brand-accent text-brand-primary'
                  : 'text-white bg-brand-primary hover:bg-brand-accent hover:text-brand-primary',
                'flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-lg group-hover:w-full duration-150 transition-all ease-[cubic-bezier(.57,.21,.69,1.25)] overflow-hidden',
              )}
            >
              <span className="w-full h-5 overflow-hidden text-sm font-medium group-hover:hidden">
                {wsName}
              </span>
              <span className="hidden w-full h-5 overflow-hidden text-sm font-medium text-center break-all group-hover:block line-clamp-1">
                {capitalizeFirstLetter(workspace.title)}
              </span>
            </button>
            // </Tooltip>
          );
        })}
        <Tooltip title="Add Workspace" placement="right">
          <Link
            to={`/${businessId}/nw-name`}
            className={`mx-auto cursor-pointer transition bg-brand-accent text-brand-primary flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-lg`}
          >
            <PlusIcon className="w-6 h-6" aria-hidden="true" />
          </Link>
        </Tooltip>
        <span className="block text-brand-primary">-</span>
      </div>
    </nav>
  );
};

export default WorkspacesBar;
