import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';
import { BusinessResponse } from '../../interfaces/businesses-response';

interface ConfirmationActivateFeaturesProps {
  show: boolean;
  closeModal: () => void;
  confirm: (bool: boolean) => void;
  data?: BusinessResponse['data']['initial_package'];
}

const ConfirmationActivateFeatures = ({
  show,
  closeModal,
  confirm,
  data,
}: ConfirmationActivateFeaturesProps) => {
  const handleClose = (bool: boolean) => {
    closeModal();
    confirm(bool);
  };

  return (
    <Dialog onClose={() => handleClose(false)} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col w-80 sm:w-[480px] lg:w-[560px] p-6">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900">
          <div className="flex flex-col pb-2 text-sm border-b-2 border-b-gray-100">
            <span className="">
              This package is valid for {data?.initial_invoice_duration ?? 0} days. Do you want to
              activate it now?
            </span>
          </div>
          <button
            type="button"
            onClick={() => handleClose(false)}
            className="ml-6 text-sm transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="flex flex-col mt-4 space-x-2">
          <span className="text-sm font-medium capitalize">{data?.title}</span>
          <span className="mt-2 text-sm font-medium capitalize">Features</span>
        </div>
        <div className="flex flex-col pl-2 mt-2">
          {data?.features.map((feature, i) => (
            <div key={i} className="flex space-x-1">
              <span className="text-sm capitalize">- {feature.title}</span>
              {feature.description ? <span>-</span> : null}
              <span className="text-sm">{feature.description}</span>
            </div>
          ))}
        </div>
        <div className="flex items-center justify-between w-auto pt-9 md:mx-0">
          <button
            type="button"
            onClick={() => handleClose(true)}
            className="h-10 ml-auto text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationActivateFeatures;
