import { XMarkIcon } from '@heroicons/react/24/outline';
import Dialog from '@mui/material/Dialog';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
interface IForm {
  title: string;
}

const schema = yup
  .object({
    title: yup.string().min(2).required(),
  })
  .required();

const formDefaultValues: IForm = {
  title: '',
};

interface NewBusinessProps {
  show: boolean;
  closeModal: (text: string) => void;
}

const NewBusiness = ({ show, closeModal }: NewBusinessProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: formDefaultValues,
    resolver: yupResolver<any>(schema),
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    closeModal(data.title);
  };

  const handleClose = (text = '') => {
    closeModal(text);
  };

  return (
    <Dialog onClose={() => handleClose()} open={show} fullWidth={false} maxWidth={'sm'}>
      <div className="flex flex-col w-80 sm:w-[480px] lg:w-[480px] p-6">
        <div className="flex items-center justify-between text-lg font-medium leading-6 text-gray-900 border-b-2 border-b-gray-100">
          <div className="flex flex-col pb-2 text-xl">
            <span className="">Create New Subsidiary</span>
            <p className="text-xs font-normal text-gray-400">Here, you can create a Subsidiary.</p>
          </div>
          <button
            type="button"
            onClick={() => handleClose()}
            className="ml-6 text-sm transition border border-transparent rounded shadow-sm self-baseline focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full pt-2">
          <div className="flex flex-col mt-2">
            <div className="flex flex-col w-full">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Subsidiary Title
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  id="title"
                  {...register('title')}
                  autoComplete="given-name"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <p className="h-8 pt-1 text-sm text-red-500 first-letter:capitalize">
                {errors.title?.message}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between w-auto pt-4 md:mx-0">
            <button
              type="submit"
              className="h-10 ml-auto text-sm tracking-wide text-white transition bg-indigo-500 border border-transparent rounded shadow-sm w-28 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default NewBusiness;
