import { useQuery } from '@tanstack/react-query';
import crmService from '../../services/crm-service';

export function useGetCrmBusinesses() {
  return useQuery([crmService.getBusinessesQueryKey], () => crmService.getBusinesses(), {
    staleTime: 1000 * 5,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
}
