import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import crmService from '../../services/crm-service';
import { customToastError } from '../../utils/custom-toast-error';
import toast from 'react-hot-toast';

export function useCreateBusiness(configOptions: UseMutationOptions<any, any, any, any> = {}) {
  const queryClient = useQueryClient();
  return useMutation((title: string) => crmService.createBusiness(title), {
    onSuccess: (data) => {
      toast.success(data.message);
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
    onSettled: () => {
      queryClient.invalidateQueries([crmService.getBusinessesQueryKey]);
    },
    ...configOptions,
  });
}
