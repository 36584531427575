import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { customToastError } from '../../utils/custom-toast-error';
import profileService from '../../services/profile-service';
import { useAuthSlice } from '../../store/auth-slice';

export function useLogout() {
  const logout = useAuthSlice((state) => state.reset);

  return useMutation(() => profileService.logout(), {
    onSuccess: () => {
      logout();
    },
    onError: (data: AxiosError) => {
      const err = data.response?.data as any;
      customToastError('Error', err?.message ?? 'Sorry there was a problem');
    },
  });
}
