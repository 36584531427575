import { Fragment, useState } from 'react';
import { useCopyToClipboard, useTitle } from 'react-use';
import { dateUtc, formatDate } from '../../utils/format-date';
import { ClipboardDocumentCheckIcon } from '@heroicons/react/24/outline';
import { useGetCrmBusinessFeature } from '../../hooks/crm/use-get-crm-business-feature';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useLogout } from '../../hooks/auth/use-logout';
import { classNames } from '../../utils/tailwind-class-names';
import { useAuthSlice } from '../../store/auth-slice';

const CrmProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [avatarError, setAvatarError] = useState(false);
  const attachment = useAuthSlice((state) => state.attachment);
  useTitle(`${import.meta.env.VITE_APP_TITLE} | CRM`);
  const getCrmBusinessFeature = useGetCrmBusinessFeature(+id!);
  const [_, copy] = useCopyToClipboard();
  const logout = useLogout();

  return (
    <div className="min-h-screen space-y-4 bg-gray-50 p-9">
      <div className="flex items-center justify-between w-full max-w-5xl mx-auto">
        <button
          type="button"
          onClick={() => navigate(-1)}
          className="w-32 h-12 text-sm text-gray-700 border border-gray-200 rounded bg-gray-50 hover:border-gray-500"
        >
          Back
        </button>
        <div className="flex items-center space-x-4">
          <a
            target="_blank"
            title="Here, you’ll find all your active features. To view and purchase available packages, press the button."
            href={import.meta.env.VITE_PORTAL_BASE_URL}
            className="flex items-center justify-center h-12 text-sm text-gray-700 border border-gray-200 rounded cursor-pointer w-52 bg-gray-50 hover:border-gray-500"
            rel="noreferrer"
          >
            Purchase
          </a>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2">
              {attachment && !avatarError ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={attachment}
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    // currentTarget.src = 'image_path_here';
                    setAvatarError(true);
                  }}
                />
              ) : (
                <span className="block w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                  <svg
                    className="w-full h-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-30 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/subsidiaries"
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        Subsidiaries
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profile"
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        Your Profile
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => logout.mutate()}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                        )}
                      >
                        Sign Out
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div className="w-full max-w-5xl mx-auto space-y-4">
        {!getCrmBusinessFeature.isLoading &&
        getCrmBusinessFeature.data?.data.status !== 'active' ? (
          <div className="flex flex-col items-center justify-center w-full text-red-400 bg-white border border-gray-100 rounded-md shadow-lg p-9">
            <div className="flex w-full font-medium">
              This subsidiary is deactivated. Check the reason or contact support.
            </div>
          </div>
        ) : null}
        <div className="flex flex-col items-center justify-center w-full bg-white border border-gray-100 rounded-md shadow-lg p-9">
          <div className="flex w-full pb-6 font-medium border-b">Service Portal </div>
          <div className="flex flex-col w-full mt-6 text-sm divide-y divide-y-gray-100">
            <div className="flex items-center justify-between h-12">
              <span>Title</span>
              <span className="capitalize">{getCrmBusinessFeature.data?.data.title}</span>
            </div>
            <div className="flex items-center justify-between h-12">
              <span>Reference Code</span>
              <button
                className="flex items-center space-x-2"
                onClick={() => copy(getCrmBusinessFeature.data?.data?.reference_code ?? '')}
              >
                <span>{getCrmBusinessFeature.data?.data.reference_code}</span>
                <ClipboardDocumentCheckIcon className="w-5 h-5 text-gray-600" />
              </button>
            </div>
            <div className="flex items-center justify-between h-12">
              <span>Status</span>
              <span className="capitalize">
                {getCrmBusinessFeature.data?.data.status === 'de_active'
                  ? 'Inactive'
                  : getCrmBusinessFeature.data?.data.status}
              </span>
            </div>
            <div className="flex items-center justify-between h-12">
              <span>Status Reason</span>
              <span>{getCrmBusinessFeature.data?.data.status_reason}</span>
            </div>
            <div className="flex flex-col pt-4 pb-4">
              <span>Features</span>
              <div className="flex flex-col w-full mt-4 space-y-4">
                {getCrmBusinessFeature.data?.data.owned_features.map((feature) => (
                  <div key={feature.id} className="flex items-center justify-between w-full">
                    <div className="flex flex-col">
                      <span className="capitalize">-{feature.title}</span>
                      {feature.status_reason ? (
                        <span className="pl-4 text-xs text-gray-400 capitalize">
                          Status Reason: {feature.status_reason}
                        </span>
                      ) : null}
                    </div>
                    <div className="flex space-x-4">
                      <div className="flex space-x-1">
                        <span className="font-medium">Start:</span>
                        <span>{formatDate(feature.start, 'dd-MM-y')}</span>
                      </div>
                      <div className="flex space-x-1">
                        <span className="font-medium">End:</span>
                        <span>{formatDate(dateUtc(feature.end), 'dd-MM-y')}</span>
                      </div>
                      <div className="flex space-x-1">
                        <span className="font-medium">Status:</span>
                        <span className="capitalize">{feature.status}</span>
                      </div>
                      <div className="flex space-x-1">
                        <span className="font-medium">Invoice Code:</span>
                        <span className="capitalize">{feature.invoice_code}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col pt-4">
              <span>Upcoming Features</span>
              <div className="flex flex-col w-full mt-4 space-y-4">
                {getCrmBusinessFeature.data?.data.future_owned_features.map((feature) => (
                  <div key={feature.id} className="flex items-center justify-between w-full">
                    <div className="flex flex-col">
                      <span className="capitalize">-{feature.title}</span>
                      {feature.status_reason ? (
                        <span className="pl-4 text-xs text-gray-400 capitalize">
                          Status Reason: {feature.status_reason}
                        </span>
                      ) : null}
                    </div>
                    <div className="flex space-x-4">
                      <div className="flex space-x-1">
                        <span className="font-medium">Start:</span>
                        <span>{formatDate(feature.start, 'dd-MM-y')}</span>
                      </div>
                      <div className="flex space-x-1">
                        <span className="font-medium">End:</span>
                        <span>{formatDate(dateUtc(feature.end), 'dd-MM-y')}</span>
                      </div>
                      <div className="flex space-x-1">
                        <span className="font-medium">Status:</span>
                        <span className="capitalize">
                          {feature.status === 'de_active' ? 'Inactive' : feature.status}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrmProject;
