import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';
import UnSplashImage from '../../components/libs/unsplash-image';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { LoginRequest } from '../../interfaces/login-interface';
import { useAuthSlice } from '../../store/auth-slice';
import authService from '../../services/auth-service';
import FormError from '../../components/form-error';
import Spinner from '../../components/spinner';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { getYear } from 'date-fns';
import VideoPlayer from '../../components/libs/video-player';

const schema = yup
  .object({
    email: yup.string().email('Please enter a valid email').required('This field is required'),
    password: yup
      .string()
      .required('This field is required')
      .min(6, ({ min }) => `must be at least ${min} characters`),
  })
  .required();

const Login = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Login`);
  const [showPassword, setShowPassword] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const navigate = useNavigate();
  const updateUser = useAuthSlice((state) => state.updateUser);
  // const updateEmail = useOtpSlice((state) => state.updateEmail);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginRequest>({
    resolver: yupResolver<any>(schema),
  });

  const {
    mutate,
    isLoading,
    error: loginError,
  } = useMutation(authService.login, {
    onSuccess: (data, { email }) => {
      if (data.status === 'email_not_verified') {
        window.location.href = `${
          import.meta.env.VITE_PORTAL_BASE_URL
        }/verify-email/${email}?redirect=${window.location.href}&project=time`;
      } else {
        updateUser(data);
        toast.success(`Welcome ${data.user.first_name}, Let's go`);
        navigate('/subsidiaries');
      }
    },
    onError: (error, { email }) => {
      if ((error as any)?.response?.data?.status === 'email_not_verified') {
        window.location.href = `${
          import.meta.env.VITE_PORTAL_BASE_URL
        }/verify-email/${email}?redirect=${window.location.href}&project=time`;
      }
    },
  });

  const onSubmit: SubmitHandler<LoginRequest> = (data) => {
    mutate(data);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenShowVideoPlayer = () => {
    setShowVideoPlayer(true);
  };

  const handleCloseShowVideoPlayer = () => {
    setShowVideoPlayer(false);
  };

  return (
    <>
      <div className="relative flex h-screen">
        <div className="items-center justify-center flex-1 hidden w-0 lg:w-full lg:h-screen lg:flex">
          <UnSplashImage />
        </div>
        <div className="relative flex flex-col justify-center flex-1 h-screen px-4 py-12 overflow-x-hidden overflow-y-auto sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="w-full max-w-sm mx-auto lg:w-96">
            <div>
              <div className="flex items-center justify-center space-x-4">
                <img
                  src="https://cdn.myselected.org/time/assets/images/selected-time-logo-w.svg"
                  alt="selected time"
                  className="h-16"
                />
              </div>
              <div className="flex items-center justify-between mt-10 text-sm text-gray-600">
                <div className="flex flex-col">
                  <span className="font-semibold">Setting up a new business?</span>
                  <button
                    onClick={handleOpenShowVideoPlayer}
                    className="text-sm text-left text-blue-600 underline transition underline-offset-2 hover:text-blue-800"
                  >
                    Click Here to see a video
                  </button>
                </div>
                <button
                  onClick={() =>
                    (window.location.href = `${import.meta.env.VITE_PORTAL_REGISTER}?redirect=${
                      window.location.href
                    }&project=time`)
                  }
                  className="flex items-center justify-center w-24 h-10 font-medium text-indigo-600 transition border-2 border-indigo-600 rounded-md hover:bg-indigo-600 hover:text-white"
                >
                  Sign Up
                </button>
              </div>
            </div>
            <div className="mt-8">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 capitalize"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      autoComplete="email"
                      {...register('email')}
                      className={`block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                        errors.email?.message ? '!border-red-500' : ''
                      }`}
                    />
                  </div>
                  <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                    {errors.email?.message}
                  </p>
                </div>
                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700 capitalize"
                  >
                    Password
                  </label>
                  <div className="relative mt-1">
                    <input
                      id="password"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="current-password"
                      {...register('password')}
                      className={`block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                        errors.password?.message ? '!border-red-500' : ''
                      }`}
                    />
                    <button
                      type="button"
                      className="absolute top-[6px] right-4"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? (
                        <EyeIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                      ) : (
                        <EyeSlashIcon className="w-6 h-6 text-gray-600 transition hover:text-gray-800" />
                      )}
                    </button>
                  </div>
                  <p className="pt-1 text-sm text-red-500 capitalize h-7 first-letter:capitalize">
                    {errors.password?.message}
                  </p>
                </div>
                <div className="flex items-center justify-end">
                  <div className="text-sm">
                    <button
                      type="button"
                      onClick={() => {
                        window.location.href = `${
                          import.meta.env.VITE_PORTAL_BASE_URL
                        }/update-password?redirect=${window.location.href}&project=time`;
                      }}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot your password?
                    </button>
                  </div>
                </div>
                <div className="pt-4">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {!isLoading ? 'Sign in' : <Spinner size="small" />}
                  </button>
                </div>
                <FormError takeSpace={true} error={loginError} />
              </form>
            </div>
          </div>
        </div>
        <span className="absolute z-10 text-xs text-gray-400 right-4 bottom-4">
          Copyright &copy; {getYear(new Date())}
        </span>
      </div>
      <VideoPlayer
        closeModal={handleCloseShowVideoPlayer}
        show={showVideoPlayer}
        title={'Sign-up'}
        url={import.meta.env.VITE_PREFIX_SIGN_UP_VIDEO}
      />
    </>
  );
};
export default Login;
