import { useState } from 'react';
import { useActiveInitialPackage } from '../../../hooks/crm/use-active-initial-pacakge';
import { useNavigate } from 'react-router-dom';
import { BusinessResponse } from '../../../interfaces/businesses-response';
import ConfirmationActivateFeatures from '../../../components/libs/confirmation-activate-features';
import { customToastError } from '../../../utils/custom-toast-error';
import { Tooltip } from '@mui/material';
import IconInfo from '~icons/mdi/information-outline';

interface BusinessesTableProps {
  data: BusinessResponse['data']['businesses'];
  initialPackage: BusinessResponse['data']['initial_package'] | undefined;
  hasFreeInitialPackage: boolean;
}

const BusinessesTable = ({ data, initialPackage, hasFreeInitialPackage }: BusinessesTableProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const activeInitialPackage = useActiveInitialPackage();
  const navigate = useNavigate();

  const handleOpenConfirmation = () => {
    setShowConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };

  const handleConfirmation = (bool: boolean) => {
    if (bool && id) {
      activeInitialPackage.mutate(id);
    }
  };

  const handleActivate = (id: number) => {
    setId(id);
    handleOpenConfirmation();
  };

  const handleEnter = (b: BusinessResponse['data']['businesses'][0]) => {
    if (b.status !== 'active') {
      customToastError(
        'Deactivate',
        'This subsidiary is deactivated. Check the reason in "Features" or contact support.',
      );
      return;
    }
    if (!b.owned_features.length) {
      if (hasFreeInitialPackage) {
        customToastError(
          'No active features',
          'Activate a trial or purchase from the Features section.',
        );
      } else {
        customToastError(
          'No Features Available',
          'You don’t have any features yet. Visit the Features section to explore and purchase what you need.',
        );
      }
      return;
    }
    if (b.owned_features.every((f) => f.status !== 'active')) {
      customToastError(
        'Features Expired',
        'Your features have expired. Visit the Features section to explore and purchase what you need.',
      );
      return;
    }
    navigate(`/${b.id}`);
  };

  return (
    <>
      <div className="w-full p-6 overflow-hidden bg-white border border-gray-100 rounded-md shadow-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6"
              >
                Subsidiary Title
              </th>
              <th
                scope="col"
                className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-sm font-semibold text-gray-700"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((b) => (
              <tr key={b.id}>
                <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 capitalize whitespace-nowrap sm:pl-6">
                  <span>{b.title}</span>
                </td>
                <td className="relative flex items-center justify-center py-4 pl-3 pr-4 space-x-4 text-sm font-medium text-center whitespace-nowrap sm:pr-6">
                  <button
                    type="button"
                    className={`block h-10 text-xs font-normal text-white bg-green-500 border rounded-md shadow-sm w-28 disabled:bg-green-600`}
                    disabled={activeInitialPackage.isLoading || !hasFreeInitialPackage}
                    onClick={() => handleActivate(b.id)}
                  >
                    Activate Trial
                  </button>
                  <button
                    type="button"
                    className="flex items-center justify-center h-10 space-x-1 text-xs font-normal text-white bg-indigo-500 border rounded-md shadow-sm w-28 disabled:bg-indigo-600"
                    disabled={activeInitialPackage.isLoading}
                    onClick={() => navigate(`/subsidiaries/${b.id}`)}
                  >
                    <span>Features</span>
                    <Tooltip
                      title={
                        <div className="flex flex-col p-2">
                          <span className="text-sm font-medium">Features?</span>
                          <p className="mt-1 text-xs">
                            You can always monitor and check the available and upcoming features of
                            the product, based on your active packages, on the Features page.
                          </p>
                        </div>
                      }
                      placement="top"
                    >
                      <span>
                        <IconInfo className="w-5 h-6 text-white" />
                      </span>
                    </Tooltip>
                  </button>
                  <button
                    type="button"
                    className="block h-10 text-xs font-normal text-white bg-blue-500 border rounded-md shadow-sm w-28"
                    onClick={() => handleEnter(b)}
                  >
                    Enter
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmationActivateFeatures
        show={showConfirmation}
        closeModal={handleCloseConfirmation}
        confirm={handleConfirmation}
        data={initialPackage}
      />
    </>
  );
};

export default BusinessesTable;
