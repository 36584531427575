import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import MockSideBar from './components/mock-side-bar';

const WelcomePage = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | WelcomePage`);
  const navigate = useNavigate();
  const { businessId } = useParams();

  return (
    <div className="flex w-full">
      <MockSideBar />
      <div className="flex justify-around w-full mx-auto pt-14 max-w-7xl">
        <div className="flex">
          <SparklesIcon className="text-indigo-500 h-14 w-14" />
          <div className="flex flex-col items-center w-[500px] ml-6 space-y-9">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl sm:tracking-tight md:text-4xl md:tracking-tight">
              Awesome! It&apos;s time to create your space
            </h1>
            <div className="space-y-6 text-lg font-medium text-gray-800">
              <p>
                Here, everything happens in a workspace, a place where you schedule your employees,
                while keeping a visual track of everyone&apos;s attendance, absences and leave
                requests.
              </p>
            </div>
            <button
              className="flex items-center justify-center w-56 h-12 text-white bg-indigo-500 rounded "
              onClick={() => navigate(`/${businessId}/nw-name`)}
            >
              Create a workspace <ChevronRightIcon className="w-6 h-6 ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
