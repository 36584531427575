import http from './axios-instance';
import { BusinessResponse } from '../interfaces/businesses-response';
import { CrmCompany } from '../interfaces/crm-company-interface';

const getBusinessesQueryKey = 'Get Businesses';
const getBusinesses = async (): Promise<BusinessResponse> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/businesses/list`;
  const res = await http.get<BusinessResponse>(url);
  return res.data;
};

const getBusinessFeaturesQueryKey = 'Get Business Feature';
const getBusinessFeatures = async (id: number): Promise<CrmCompany> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/businesses/owned_features`;
  const res = await http.get<CrmCompany>(url, { params: { id } });
  return res.data;
};

const createBusiness = async (title: string): Promise<any> => {
  const url = `${import.meta.env.VITE_BASE_URL}/api/v1/merchant/businesses/create`;
  const res = await http.post(url, { title });
  return res.data;
};

const activeInitialPackage = async (id: number): Promise<any> => {
  const url = `${
    import.meta.env.VITE_BASE_URL
  }/api/v1/merchant/businesses/activate_initial_package`;
  const res = await http.post(url, { id });
  return res.data;
};

const crmService = {
  getBusinessesQueryKey,
  getBusinesses,
  createBusiness,
  activeInitialPackage,
  getBusinessFeaturesQueryKey,
  getBusinessFeatures,
};

export default crmService;
