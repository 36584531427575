import { useQuery } from '@tanstack/react-query';
import crmService from '../../services/crm-service';

export function useGetCrmBusinessFeature(id: number | null) {
  return useQuery(
    [crmService.getBusinessFeaturesQueryKey, id],
    () => crmService.getBusinessFeatures(id!),
    {
      enabled: !!id,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    },
  );
}
