import { Disclosure } from '@headlessui/react';
import {
  RectangleStackIcon,
  HomeIcon,
  CalendarDaysIcon,
  Cog6ToothIcon,
  ArrowRightIcon,
  SpeakerWaveIcon,
  ChevronRightIcon,
  DeviceTabletIcon,
} from '@heroicons/react/24/outline';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useEmployees } from '../../../hooks/use-employees';
import { classNames } from '../../../utils/tailwind-class-names';
import { useActiveWorkspaceSlice } from '../../../store/active-workspace-slice';

const navigation = [
  { name: 'Dashboard', end: true, href: '', icon: HomeIcon },
  { name: 'Calendar', end: false, href: 'calendar', icon: CalendarDaysIcon, bold: true },
  {
    name: 'Staff Workspace',
    end: false,
    href: 'employee-work-space',
    icon: RectangleStackIcon,
    children: [
      { name: 'Attendance', end: false, href: 'employee-work-space/attendance' },
      { name: 'Time Off Requests', end: false, href: 'employee-work-space/leaves-of-absences' },
      { name: 'Shift Swap Requests', end: false, href: 'employee-work-space/shifts-swap' },
      { name: 'Employees', end: false, href: 'employee-work-space/employees' },
    ],
  },
  { name: 'Central Terminal', end: false, href: 'devices', icon: DeviceTabletIcon },
  {
    name: 'Reports',
    end: false,
    href: 'reports',
    icon: SpeakerWaveIcon,
    children: [
      { name: 'Staff Work Time', end: false, href: 'staffs-worktime-report' },
      { name: 'Top-Bottom X', end: false, href: 'top-bottom-report' },
      { name: 'Lock System', end: false, href: 'lock-system' },
      { name: 'Logs', end: false, href: 'logs-report' },
    ],
  },
  {
    name: 'Settings',
    end: false,
    href: 'settings',
    icon: Cog6ToothIcon,
    children: [
      { name: 'Department', end: false, href: 'settings/department' },
      { name: 'Job Title', end: false, href: 'settings/job-title' },
      { name: 'Time Off', end: false, href: 'settings/time-off' },
      { name: 'Workspace Details', end: false, href: 'settings/company' },
      { name: 'Locations', end: false, href: 'settings/locations' },
    ],
  },
];

interface SidebarProps {
  toggleDrawer?: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const SideBar = ({ toggleDrawer }: SidebarProps) => {
  const location = useLocation();
  const { businessId } = useParams();
  const pathName = location.pathname;
  const isSettings = pathName.search('settings');
  const isEmployeeWorkSpace = pathName.search('employee-work-space');
  const isSchedule = pathName.search('schedule');
  const wsName = useActiveWorkspaceSlice((state) => state.workspace?.sub_domain);
  const { data: employees = [], isLoading: empIsLoading } = useEmployees();

  return (
    <div className="flex h-full">
      <div className="flex lg:flex-shrink-0">
        <div className="flex flex-col w-[270px]">
          <div className="flex flex-col flex-1 min-h-0 bg-gray-100 border-r border-gray-200">
            <nav className="flex-1 pt-5 pb-4 overflow-y-auto" aria-label="Sidebar">
              <div className="px-2 space-y-2">
                {navigation.map((item) =>
                  !item.children ? (
                    <NavLink
                      key={item.name}
                      {...(item.end && { end: true })}
                      to={`/${businessId}/${wsName}/${item.href}`}
                      onClick={(e) => toggleDrawer && toggleDrawer(e)}
                      className={({ isActive }) => {
                        return `${
                          isActive
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                        } group flex items-center p-3 text-sm font-medium rounded-md`;
                      }}
                    >
                      {({ isActive }) => (
                        <>
                          <item.icon
                            className={classNames(
                              isActive
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 h-6 w-6',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                          {item.bold &&
                          // workspaces.length === 1 &&
                          !empIsLoading &&
                          !employees.length ? (
                            <>
                              <span className="animate-pulse ml-auto inline-flex items-center rounded-full bg-green-100 px-2.5 py-1 text-xs font-medium text-green-800 shadow-md">
                                Start Here
                              </span>
                              <span className="flex items-center justify-center w-6 h-6 ml-2 bg-green-100 rounded-full shadow-md">
                                <ArrowRightIcon className="w-4 h-4 text-indigo-600" />
                              </span>
                            </>
                          ) : null}
                        </>
                      )}
                    </NavLink>
                  ) : (
                    <Disclosure
                      as="div"
                      key={item.name}
                      defaultOpen={
                        (item.name === 'Business Registry' && isSettings >= 0) ||
                        (item.name === 'Employee Workspace' && isEmployeeWorkSpace >= 0)
                          ? true
                          : false
                      }
                      className="space-y-1"
                    >
                      {({ open }) => {
                        return (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                open
                                  ? 'bg-gray-200 text-gray-900'
                                  : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                'group w-full flex items-center p-3 text-left text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500',
                              )}
                            >
                              <item.icon
                                className="flex-shrink-0 w-6 h-6 mr-3 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                              <span className="flex-1">{item.name}</span>
                              <ChevronRightIcon
                                className={classNames(
                                  open ? 'text-gray-500 rotate-90' : 'text-gray-400',
                                  'ml-3 flex-shrink-0 h-5 w-5 transition group-hover:text-gray-500',
                                )}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-1">
                              {item.children.map((subItem) => (
                                <NavLink
                                  key={subItem.name}
                                  to={`/${businessId}/${wsName}/${subItem.href}`}
                                  onClick={(e) => toggleDrawer && toggleDrawer(e)}
                                  className={({ isActive }) => {
                                    return `${
                                      isActive ||
                                      (item.name === 'Employee Workspace' &&
                                        subItem.name === 'Schedule' &&
                                        isSchedule >= 0)
                                        ? 'bg-gray-200 text-gray-900'
                                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                                    } group flex items-center p-3 pl-11 text-sm font-medium rounded-md`;
                                  }}
                                >
                                  {subItem.name}
                                </NavLink>
                              ))}
                            </Disclosure.Panel>
                          </>
                        );
                      }}
                    </Disclosure>
                  ),
                )}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
