import { Fragment, useState, useRef } from 'react';
import { useTitle } from 'react-use';
import { useGetCrmBusinesses } from '../../hooks/crm/use-get-crm-businesses';
import { useCreateBusiness } from '../../hooks/crm/use-create-crm-businnes';
import BusinessesTable from './components/businesses-table';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { classNames } from '../../utils/tailwind-class-names';
import { useAuthSlice } from '../../store/auth-slice';
import { useLogout } from '../../hooks/auth/use-logout';
import NewBusiness from './components/new-business';
import { Tooltip } from '@mui/material';
import IconInfo from '~icons/mdi/information-outline';

const Businesses = () => {
  useTitle(`${import.meta.env.VITE_APP_TITLE} | Job Title`);
  const [avatarError, setAvatarError] = useState(false);
  const [isNewBusinessOpen, setIsNewBusinessOpen] = useState(false);
  const attachment = useAuthSlice((state) => state.attachment);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const getCrmBusinesses = useGetCrmBusinesses();
  const createBusiness = useCreateBusiness();
  const logout = useLogout();

  const handleNewBusiness = (text: string) => {
    if (!text) {
      setIsNewBusinessOpen(false);
    } else {
      createBusiness.mutate(text);
      setIsNewBusinessOpen(false);
    }
  };

  return (
    <>
      <div className="flex flex-col min-h-screen space-y-4 bg-gray-50 p-9">
        <div className="flex flex-col w-full max-w-5xl p-6 mx-auto space-y-1 text-sm text-gray-700 bg-white shadow">
          <h3 className="text-sm font-semibold">Step 1: Create Your Subsidiaries</h3>
          <p className="pl-4 text-sm">
            In our system, subsidiaries are the different businesses or shops you own. You can
            create as many subsidiaries as you like, depending on how many shops or locations you
            have.
          </p>
          <h3 className="text-sm font-semibold">Step 2: Activate Package</h3>
          <p className="pl-4 text-sm">
            Before entering your subsidiary, you need to have an active package. This can either be
            a trial package or a paid package.
            <br />
            <b>If a Trial Package is Available:</b>
            <br />
            If the product offers a trial package, simply press the Activate Trial button. Once the
            trial is activated, press Enter, and everything will be ready for you to use.
            <br />
            <b>If No Trial Package is Available:</b>
            <br />
            If you tried to activate a trial package but none were available, don&lsquo;t worry.
            Simply go to the Packages page. Use the Purchase button and follow the steps to buy a
            package. Once the package is purchased, you can press Enter to start using the product.
          </p>
          <h3 className="text-sm font-semibold">Step 3: Access Your Subsidiary</h3>
          <p className="pl-4 text-sm">
            Press “Enter” to access your subsidiary and enjoy using your product.
          </p>
        </div>
        <div className="flex items-center justify-between w-full max-w-5xl mx-auto">
          <button
            type="button"
            className="flex items-center justify-center h-10 space-x-1 text-xs font-normal text-white bg-blue-500 border rounded-md shadow-sm w-36"
            onClick={() => setIsNewBusinessOpen(true)}
          >
            <span>New Subsidiary</span>
            <Tooltip
              title={
                <div className="flex flex-col p-2">
                  <span className="text-sm font-medium">Subsidiary?</span>
                  <p className="mt-1 text-xs">
                    A subsidiary is simply a shop or business you own. For instance, if you operate
                    two shops, you can create two subsidiaries—one for each shop. This allows you to
                    manage and organise each business separately.
                  </p>
                </div>
              }
              placement="top"
            >
              <span>
                <IconInfo className="w-5 h-6 text-white" />
              </span>
            </Tooltip>
          </button>
          <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2">
              {attachment && !avatarError ? (
                <img
                  className="w-8 h-8 rounded-full"
                  src={attachment}
                  alt="user"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    // currentTarget.src = 'image_path_here';
                    setAvatarError(true);
                  }}
                />
              ) : (
                <span className="block w-8 h-8 overflow-hidden bg-gray-100 rounded-full">
                  <svg
                    className="w-full h-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
              )}
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-30 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/profile"
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700',
                        )}
                      >
                        {' '}
                        Your Profile{' '}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        onClick={() => logout.mutate()}
                        className={classNames(
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                        )}
                      >
                        {' '}
                        Sign Out{' '}
                      </a>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <div
          ref={containerRef}
          className="flex flex-col items-start w-full max-w-5xl mx-auto space-x-4"
        >
          <BusinessesTable
            initialPackage={getCrmBusinesses.data?.data?.initial_package}
            data={getCrmBusinesses.data?.data.businesses ?? []}
            hasFreeInitialPackage={getCrmBusinesses.data?.data.has_free_initial_package ?? false}
          />
        </div>
      </div>
      <NewBusiness show={isNewBusinessOpen} closeModal={handleNewBusiness} />
    </>
  );
};

export default Businesses;
